<template>
<div id="app">
    <div v-show="$bus.ving.length" class="page-loading-container">
        <div class="page-loading" >{{ loadingMsg }}</div>
    </div>
    <keep-alive >
      <router-view v-if="$route.meta.keepAlive" :key="viewKey"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
</div>
</template>

<script>
export default {
    computed: {
        loadingMsg() {
            const item = this.$bus.ving[0];
            return typeof item === 'string' ? item : '加载中…';
        },
    },
    watch: {
        '$route'(to, from) {
            //tab页刷新，其他保留缓存
            if(['/index','/mba','/home'].includes(from.path) && ['/elective-lesson','/homework'].includes(to.path)){
                this.viewKey += 1; // 路由变化时更改 key
            }
        }
    },
    data(){
        return {
            viewKey: 0
        }
    }
}
</script>
<style lang="less">
@import "./common/less/common.less";
@import "./common/less/loading.less";
</style>
<style lang="less" scoped>
.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
