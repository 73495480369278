<template>
  <!-- 父类型34为引用块特殊处理  -->
  <div
    v-if="children"
    class="block docx-ordered-block"
    :class="[fatherType == 34 ? 'quote-container-render-unit' : '', `block-${children.blockId}`, children.blockStyles && children.blockStyles.textBackground ? 'background':'']"
  >
    <div class="list-wrapper ordered-list">
      <div
        class="list"
        :class="getAlignFlex(children.blockAttr.ordered.style.align)"
      >
        <div contenteditable="false" class="order orderUnedit">
          {{ getOrder() + "." }}
        </div>
        <div class="list-content">
          <div class="text-editor" :class="pageStyle">
              <v-spantext :elements="elements" :children="children"></v-spantext>
          </div>
        </div>
      </div>
      <v-highlight :blockId="children.blockId"></v-highlight>
      <div
        class="list-children"
        style="padding-left: 18px; margin-left: 7px"
        v-if="children.childrens && children.childrens.length > 0"
      >
        <div class="render-unit-wrapper">
          <slot :olIndex="olIndex + 1"></slot>
        </div>
      </div>
    </div>
    <v-blockbackground v-if="children.blockStyles && children.blockStyles.textBackground" :backgroundColor="children.blockStyles.textBackground"></v-blockbackground>
  </div>
</template>
<script>
import { pageStyles, getEleStyle, getAlignFlex } from "../utils/style";
import { createCellPos, romanize, isUrlRegExp, openUrl } from "../utils/utils";

export default {
  name: "ol-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
    fatherType: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    prevChild: {
      type: Object,
      default: null,
    },
    fatherBlockId: {
      type: String,
      default: "",
    },
    olIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    elements() {
      return this.children.blockAttr.ordered.elements || [];
    },
    pageStyle() {
      let dataStyle = this.children.blockAttr.ordered.style;
      let className = "";
      for (let key in dataStyle) {
        if (pageStyles[key]) {
          let value = dataStyle[key];
          className += pageStyles[key][value] + "";
        }
      }
      return className; 
    },
  },
  data() {
    return {
      getEleStyle,
      getAlignFlex,
      isUrlRegExp,
      openUrl,
    };
  },
  methods: {
    getOrder() { 
      let sequence = this.children.blockAttr.ordered.style.sequence;
      let order;
      if(sequence){
        order = this.children.order;
      }else{
        let key = `setOrder-${this.fatherBlockId}`;
        if (!this.prevChild || this.prevChild.type != 13) {
          sessionStorage.setItem(key,1)
        } else {
          sessionStorage.setItem(key, Number(sessionStorage.getItem(key)) + 1)
        }
        order = Number(sessionStorage.getItem(key));
      }
      if (this.olIndex % 3 == 2) {
        order = romanize(order);
      } else if (this.olIndex % 3 == 1) {
        order = createCellPos(order - 1);
      }
      return order;
    },
  },
};
</script>
<style scoped>
.list-wrapper {
  margin: 0 -2px;
  padding: 0 2px;
}
.list-wrapper:before {
  content: "";
  display: table;
}
.list-wrapper .list {
  display: flex;
  position: relative;
  align-items: baseline;
  font-size: 16px;
  line-height: 1.8;
  margin: 0 -2px;
  padding: 0 2px;
}
.list-wrapper .order {
  white-space: nowrap;
  line-height: 26px;
  color: var(--primary-content-default);
  /* font-family: "Helvetica Neue", "Consolas" !important; */
  padding: 0 2px;
  border-radius: 2px;
  height: 20px;
}
.list-wrapper .order {
  margin-right: 6px;
  min-width: 18px;
  height: 26px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  -webkit-user-select: none !important;
}
.list-wrapper .orderUnedit {
  cursor: default;
}
.list-wrapper .list-content {
  flex: 1 1 0;
  min-width: 1px;
  display: flex;
  flex-direction: column;
}
.list-wrapper .list.list-align-center .list-content {
  flex: 0 1 auto;
}
.list-wrapper .list.list-align-right .list-content {
  flex: 0 1 auto;
}
</style>