import Vue from 'vue';
import VueRouter from 'vue-router';
import vway from "ether-vway";
import util from '@/common/util/util.js';
import WxSDK from '@/common/wx-sdk.js';
import routeNameGroup from '@/common/route-name'
import * as bekit from 'bekit';
const Ua = bekit.helper.Ua;
import { canFakeLogin, canPreLogin, preLogin, canDelayLogin } from '@/common/wx-base-login.js';

Vue.use(vway, { shortcut: "$" });
Vue.use(VueRouter);

const displayQueue = Ua.isMob() ? ['mobile', 'desktop'] : ['desktop', 'mobile'];
const routes = {
  'desktop': {
    component: () =>
      import( /* webpackChunkName: 'layout-desktop' */ "./layouts/desktop.vue"),
    children: [{
      path: '/login',
      component: () =>
        import( /* webpackChunkName: 'login' */ "./views/desktop/login.vue"),
      meta: {
        asyncShare: true
      }
    },{
      name:'fsdoc',
      path: '/fs-doc/:acl/:id',
      component: () =>
        import( /* webpackChunkName: 'login' */ "./views/desktop/fsdoc/index.vue"),
      meta: {
        requiresAuth: true,
      },
      beforeEnter: (to, from, next) => {
        //html2canvas
        appendPlugins('https://cdn.yitang.top/upload/ether-public/yitang/1703146526728a306423b87316c16f9d6222dc4b9dc90ee33f.js')
        appendElementIcon(next)
      }
    }],
  },
  'mobile': {
    component: () =>
      import( /* webpackChunkName: 'layout-mobile' */ "./layouts/mobile.vue"),
    children: [{
        path: '/promotion/coupon-share/:sid',
        component: () =>
          import( /* webpackChunkName: 'promotion-coupon-share' */ "./views/mobile/promotion/coupon-share.vue"),
        meta: {
          requiresAuth: true,
          asyncShare: true
        }
      },
      {
        path: '/air',
        component: () =>
          import( /* webpackChunkName: 'air' */ "./views/mobile/air/index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/share-admin-only',
        component: () =>
          import( /* webpackChunkName: 'air' */ "./views/mobile/share/index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/activity/view',
        component: () =>
          import( /* webpackChunkName: 'promotion-activity' */ "./views/mobile/promotion/activity.vue"),
        meta: {
          requiresAuth: true,
          title: '一堂活动中心',
          shareInfo: {
            title: '一堂活动中心',
          }
        }
      },
      {
        path: '/summary',
        component: () =>
          import( /* webpackChunkName: 'summary' */ "./views/mobile/promotion/summary.vue"),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '山为鉴·山顶见',
          shareInfo: {
            title: '山为鉴·山顶见',
          }
        }
      },
      {
        path: '/privacy',
        component: () =>
          import( /* webpackChunkName: 'privacy' */ "./views/mobile/promotion/privacy.vue"),
        meta: {
          requiresAuth: true,
          title: '隐私须知',
          shareInfo: {
            title: '隐私须知',
          }
        }
      },
      {
        path: '/promotion/marathon',
        component: () =>
          import( /* webpackChunkName: 'promotion-marathon' */ "./views/mobile/promotion/marathon.vue"),
        meta: {
          requiresAuth: true,
          title: '一堂2024秋季马拉松',
          shareInfo: {
            title: '一堂2024秋季马拉松',
          }
        }
      },
      {
        path: '/promotion/new-scholarship',
        component: () =>
          import( /* webpackChunkName: 'promotion-new-scholarship' */ "./views/mobile/promotion/new-scholarship.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/promotion/student-info',
        component: () =>
          import( /* webpackChunkName: 'promotion-student-info' */ "./views/mobile/promotion/student-info.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/promotion/student-info-equity',
        component: () =>
          import( /* webpackChunkName: 'promotion-student-info' */ "./views/mobile/promotion/student-info-equity.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/promotion/student-info-simple',
        component: () =>
          import( /* webpackChunkName: 'promotion-student-info-simple' */ "./views/mobile/promotion/student-info-simple.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/promotion/student-info-recommend',
        component: () =>
          import( /* webpackChunkName: 'promotion-student-info-recommend' */ "./views/mobile/promotion/student-info-recommend.vue"),
        meta: {
          requiresAuth: true,
          title: '一堂 - 学籍表',
          shareInfo: {
            title: '一堂 - 学籍表',
          }
        }
      },
      {
        path: '/promotion/student-info-neo',
        component: () =>
          import( /* webpackChunkName: 'promotion-student-info-neo' */ "./views/mobile/promotion/student-neo.vue"),
        meta: {
          requiresAuth: true,
          shareInfo: {
            title: '一堂-学籍表',
            desc: '为了提供更全面的服务，请及时完成学籍填写'
          }
        }
      },
      {
        path: '/promotion/student-info-evo',
        component: () =>
          import( /* webpackChunkName: 'promotion-student-info-evo' */ "./views/mobile/promotion/student-neo.vue"),
        meta: {
          requiresAuth: true,
          shareInfo: {
            title: '一堂-学籍表',
            desc: '为了提供更全面的服务，请及时完成学籍填写'
          }
        }
      },
      {
        path: '/promotion/student-info-evo2',
        component: () =>
          import( /* webpackChunkName: 'promotion-student-info-evo' */ "./views/mobile/promotion/student-evo.vue"),
        meta: {
          requiresAuth: true,
          shareInfo: {
            title: '一堂-学籍表',
            desc: '为了提供更全面的服务，请及时完成学籍填写'
          }
        }
      },
      {
        path: '/promotion/student-info-address',
        component: () =>
          import( /* webpackChunkName: 'promotion-student-info-address' */ "./views/mobile/promotion/student-address.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/promotion/admission',
        name: routeNameGroup.ADMISSION ,
        component: () =>
          import( /* webpackChunkName: 'promotion-admission' */ "./views/mobile/promotion/replay-rebuild.vue"),
        meta: {
          requiresAuth: true,
          title: '线上实战营12.2开营',
          shareInfo: {
            title: '以太一堂"7天改变计划"火热招生，快来报名融资突破',
            desc: '张一鸣天使投资人周子敬主理，手把手带你学习融资的全套打法'
          },
          special: false
        }
      },
      {
        path: '/promotion/business',
        name: routeNameGroup.BUSINESS,
        component: () =>
          import( /* webpackChunkName: 'promotion-admission' */ "./views/mobile/promotion/replay-rebuild.vue"),
        //import( /* webpackChunkName: 'promotion-admission' */ "./views/mobile/promotion/replay-double.vue"),
        meta: {
          requiresAuth: true,
          title: '线上实战营12.2开营',
          shareInfo: {
            title: '以太一堂“7天改变计划”火热招生，快来报名创业复盘',
            desc: '以太创服创始人周子敬&迅雷创始人程浩联合授课，带你加速验证模式'
          },
          special: false
        }
      },
      {
        path: '/marathon',
        component: () =>
          import( /* webpackChunkName: 'marathon' */ "./views/desktop/marathon.vue"),
        meta: {
          requiresAuth: true,
          title: '一堂表白墙：2024秋季马拉松',
          shareInfo: {
            title: '一堂表白墙：2024秋季马拉松',
            desc: '特别感谢自己，当初没有错过一堂'
          },
        }
      },
      {
        path: '/love',
        component: () =>
          import( /* webpackChunkName: 'promotion-sales' */ "./views/mobile/promotion/show-love.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•表白墙',
          shareInfo: {
            title: '以太一堂「表白墙」',
            desc: '感谢你在这里学习，我们记得你～'
          },
        }
      },
      {
        path: '/promotion/sales/:goodname',
        component: () =>
          import( /* webpackChunkName: 'promotion-sales' */ "./views/mobile/promotion/sales.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•大会员',
          shareInfo: {
            title: '以太一堂「大会员」全新发布',
            desc: '务实创业+真实训练+切实有用，加入会员立享5大权益'
          },
        }
      },
      {
        path: '/promotion/member',
        redirect: '/promotion/sales/member',
        component: () =>
          import( /* webpackChunkName: 'promotion-member' */ "./views/mobile/promotion/member.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•大会员',
          shareInfo: {
            title: '以太一堂「大会员」全新发布',
            desc: '务实创业+真实训练+切实有用，加入会员立享5大权益'
          },
          special: true
        }
      },
      {
        path: '/promotion/member-replay',
        redirect: '/promotion/sales/member',
        component: () =>
          import( /* webpackChunkName: 'promotion-member-replay' */ "./views/mobile/promotion/member-81-82.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•大会员',
          shareInfo: {
            title: '以太一堂「大会员」全新发布',
            desc: '务实创业+真实训练+切实有用，加入会员立享5大权益'
          },
          special: true
        }
      },
      {
        path: '/promotion/member-packet',
        component: () =>
          import( /* webpackChunkName: 'promotion-member-packet' */ "./views/mobile/promotion/member-packet.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•大会员',
          shareInfo: {
            title: '以太一堂「大会员」全新发布',
            desc: '务实创业+真实训练+切实有用，加入会员立享5大权益'
          },
        }
      },
      {
        path: '/promotion/member-mba',
        component: () =>
          import( /* webpackChunkName: 'promotion-member-packet' */ "./views/mobile/promotion/member-mba.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•大会员',
          shareInfo: {
            title: '以太一堂「大会员」全新发布',
            desc: '务实创业+真实训练+切实有用，加入会员立享5大权益'
          },
        }
      },
      {
        path: '/promotion/member-renew-2021',
        component: () =>
          import( /* webpackChunkName: 'promotion-member-renew' */ "./views/mobile/promotion/member-renew.vue"),
        meta: {
          requiresAuth: true,
          title: '一堂大会员两周年',
          shareInfo: {
            title: '一堂大会员两周年',
            desc: '一堂两周年限时折扣续费福利，还有更多续费好礼相送！'
          }
        }
      },
      {
        path: '/promotion/enterprise',
        component: () =>
          import( /* webpackChunkName: 'promotion-enterprise' */ "./views/mobile/promotion/enterprise.vue"),
        meta: {
          requiresAuth: true,
          title: '一堂集体学习（企业版）',
          shareInfo: {
            title: '一堂集体学习（企业版）',
            desc: '共同打造学习型组织'
          }
        }
      },
      {
        path: '/promotion/collection',
        component: () =>
          import( /* webpackChunkName: 'promotion-member' */ "./views/mobile/promotion/collection.vue"),
        meta: {
          requiresAuth: true,
          title: '一起爬山',
          shareInfo: {
            title: '欢迎你报名一堂课程，带你翻过3座大山',
            desc: '每个想做大的创业者，必须翻过业务、股权、融资这3座大山'
          },
          special: true
        }
      },
      {
        path: '/promotion/replay-internal',
        name: routeNameGroup.INTERNAL,
        component: () =>
          import( /* webpackChunkName: 'promotion-replay-internal' */ "./views/mobile/promotion/replay-rebuild.vue"),
        meta: {
          requiresAuth: true,
          title: '线上实战营12.2开营',
          shareInfo: {
            title: '以太一堂"7天改变计划"火热招生，快来报名融资突破',
            desc: '张一鸣天使投资人周子敬主理，手把手带你学习融资的全套打法'
          },
          special: false
        }
      },
      {
        path: '/promotion/replay-note',
        component: () =>
          import( /* webpackChunkName: 'promotion-replay-internal' */ "./views/mobile/promotion/replay-note.vue"),
        meta: {
          requiresAuth: true,
          title: '线上实战营12.2开营',
          shareInfo: {
            title: '以太一堂"7天改变计划"火热招生，快来报名融资突破',
            desc: '张一鸣天使投资人周子敬主理，手把手带你学习融资的全套打法'
          },
        }
      },
      {
        path: '/promotion/replay-special',
        component: () =>
          import( /* webpackChunkName: 'promotion-replay-internal' */ "./views/mobile/promotion/replay-double.vue"),
        meta: {
          requiresAuth: true,
          title: '线上实战营12.2开营',
          shareInfo: {
            title: '以太一堂"7天改变计划"火热招生，快来报名融资突破',
            desc: '张一鸣天使投资人周子敬主理，手把手带你学习融资的全套打法'
          },
          free: false,
          special: true
        }
      },
      {
        path: '/promotion/replay',
        name: routeNameGroup.ORIGIN,
        component: () =>
          import( /* webpackChunkName: 'promotion-replay' */ "./views/mobile/promotion/replay-rebuild.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•复盘营',
          shareInfo: {
            title: '以太一堂「复盘营」全新发布',
            desc: '务实创业+真实训练+切实有用，复盘营！'
          },
          free: false,
          special: false
        }
      },
      {
        path: '/promotion/replayB',
        name: routeNameGroup.REPLAYB,
        component: () =>
          import( /* webpackChunkName: 'promotion-replay' */ "./views/mobile/promotion/replay-rebuild.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•复盘营',
          shareInfo: {
            title: '以太一堂「复盘营」全新发布',
            desc: '务实创业+真实训练+切实有用，复盘营！'
          },
          free: false,
          special: false
        }
      },
      {
        path: '/fupan/air',
        component: () =>
          import( /* webpackChunkName: 'fupan-air' */ "./views/mobile/promotion/replay-air.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•复盘营',
          shareInfo: {
            title: '以太一堂「复盘营」全新发布',
            desc: '务实创业+真实训练+切实有用，复盘营！'
          },
        }
      },
      {
        path: '/promotion/management',
        name: routeNameGroup.MANAGEMENT,
        redirect: '/promotion/replay',
        component: () =>
            import( /* webpackChunkName: 'promotion-management' */ "./views/mobile/promotion/replay-rebuild.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•复盘营',
          shareInfo: {
            title: '以太一堂「复盘营」全新发布',
            desc: '务实创业+真实训练+切实有用，复盘营！'
          },
          bundle: 'management',
          free: false,
          special: false,
          payButton: "https://cdn.yitang.top/upload/ether-public/yitang/168977429052b771bb6a02552c6ebd58df7696525a2b757be2.png",
          images: [
            'https://cdn.yitang.top/upload/ether-public/yitang/168977429097a33b68550fe57ab6e395515372e7abf3557ead.png',
            'https://cdn.yitang.top/upload/ether-public/yitang/16897742909f405133b868701644494ef8e5f7b4fa42476306.png',
            'https://cdn.yitang.top/upload/ether-public/yitang/1689774290f07f1484e56309d457eae639bd6b6d090158d44d.png',
            'https://cdn.yitang.top/upload/ether-public/yitang/16897742906c117fd1c208e81aa52b816dae8c4882ab05c678.png',
            'https://cdn.yitang.top/upload/ether-public/yitang/16897742904f95ce5cc664a98e0607deb80cfd3013bf526046.png',
          ]
        }
      },
      {
        path: '/promotion/management-free',
        name: routeNameGroup.MANAGEMENTFREE,
        redirect: '/promotion/replay-free',
        component: () =>
            import( /* webpackChunkName: 'promotion-management' */ "./views/mobile/promotion/replay-rebuild.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•复盘营',
          shareInfo: {
            title: '以太一堂「复盘营」全新发布',
            desc: '务实创业+真实训练+切实有用，复盘营！'
          },
          bundle: 'management',
          free: true,
          special: false,
          payButton: "https://cdn.yitang.top/upload/ether-public/yitang/168977429078b7d1d5bad3c332a2a331720b63f040333b18f0.png",
          images: [
            'https://cdn.yitang.top/upload/ether-public/yitang/168977429097a33b68550fe57ab6e395515372e7abf3557ead.png',
            'https://cdn.yitang.top/upload/ether-public/yitang/16897742909f405133b868701644494ef8e5f7b4fa42476306.png',
            'https://cdn.yitang.top/upload/ether-public/yitang/1689774290f07f1484e56309d457eae639bd6b6d090158d44d.png',
            'https://cdn.yitang.top/upload/ether-public/yitang/16897742906c117fd1c208e81aa52b816dae8c4882ab05c678.png',
            'https://cdn.yitang.top/upload/ether-public/yitang/16897742904f95ce5cc664a98e0607deb80cfd3013bf526046.png',
          ]
        }
      },
      {
        path: '/promotion/replay-2023',
        name: routeNameGroup.REPLAY2023,
        component: () =>
            import( /* webpackChunkName: 'promotion-replay' */ "./views/mobile/promotion/replay-rebuild.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•复盘营',
          shareInfo: {
            title: '以太一堂「复盘营」全新发布',
            desc: '务实创业+真实训练+切实有用，复盘营！'
          },
          free: false,
          special: false
        }
      },
      {
        path: '/promotion/replay-free',
        name: routeNameGroup.FREE,
        component: () =>
          import( /* webpackChunkName: 'promotion-replay-free' */ "./views/mobile/promotion/replay-rebuild.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•大会员',
          shareInfo: {
            title: '以太一堂"7天改变计划"火热招生，快来报名融资突破',
            desc: '张一鸣天使投资人周子敬主理，手把手带你学习融资的全套打法'
          },
          special: false,
          free: true,
          payButton: "https://cdn.yitang.top/upload/ether-public/yitang/1672831738bb3204b83186771a6dc54b2d338e1ef4cb14fa63.png"
        },
        beforeEnter: (to, from, next) => {
          appendElementIcon(next)
        }
      },
      {
        path: '/promotion/replayC',
        name: routeNameGroup.REPLAYC,
        component: () =>
          import( /* webpackChunkName: 'promotion-replayC' */ "./views/mobile/promotion/replay-rebuild.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•复盘营',
          shareInfo: {
            title: '以太一堂「复盘营」全新发布',
            desc: '务实创业+真实训练+切实有用，复盘营！'
          },
          special: false,
          baseAuth: true, //静默白名单
          delayAuth: true
        }
      },
      {
        path: '/promotion/replay-dy',
        name: routeNameGroup.DY,
        component: () =>
          import( /* webpackChunkName: 'promotion-dy' */ "./views/mobile/promotion/replay-dy.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•复盘营',
          shareInfo: {
            title: '以太一堂「复盘营」全新发布',
            desc: '务实创业+真实训练+切实有用，复盘营！'
          },
          bundle: 'replay',
          special: true,
          fakeLogin: false, //假身份自动登录
          payButton: "https://cdn.yitang.top/upload/ether-public/yitang/1685364392ede396fbf96535f975c929bc638386b0637a9a9b.png",
          images: [
            "https://cdn.yitang.top/upload/ether-public/yitang/1649335444bcc836bae2ea3957c1e4a0416944fc3232e30482.png",
            "https://cdn.yitang.top/upload/ether-public/yitang/1649335444c53c4b92db2d3fb59da745483395d85369fde99e.png",
            "https://cdn.yitang.top/upload/ether-public/yitang/1682837932d08fc0114f7e9da25e1f8ca93c1e5708c197070a.png",
            "https://cdn.yitang.top/upload/ether-public/yitang/168283793060af79160a4760a3873115bba7b58f77968f9db9.png",
            "https://cdn.yitang.top/upload/ether-public/yitang/16821484275883256f80fe6ff793e0e446c05c7706cfe1c71d.png",
            "https://cdn.yitang.top/upload/ether-public/yitang/168274951516177c30b1028c98b52e7df82ce3bbf9f9a8e5b9.png",
          ],
        }
      },
      {
        path: '/promotion/replay-dy-special',
        name: routeNameGroup.DYSPECIAL,
        component: () =>
          import( /* webpackChunkName: 'promotion-dy' */ "./views/mobile/promotion/replay-dy-special.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•复盘营',
          shareInfo: {
            title: '以太一堂「复盘营」全新发布',
            desc: '务实创业+真实训练+切实有用，复盘营！'
          },
          bundle: 'replay',
          special: false,
          fakeLogin: false, //假身份自动登录
          payButton: "https://cdn.yitang.top/upload/ether-public/yitang/1685364392ede396fbf96535f975c929bc638386b0637a9a9b.png",
          images: [
            "https://cdn.yitang.top/upload/ether-public/yitang/1649335444bcc836bae2ea3957c1e4a0416944fc3232e30482.png",
            "https://cdn.yitang.top/upload/ether-public/yitang/1649335444c53c4b92db2d3fb59da745483395d85369fde99e.png",
            "https://cdn.yitang.top/upload/ether-public/yitang/1682837932d08fc0114f7e9da25e1f8ca93c1e5708c197070a.png",
            "https://cdn.yitang.top/upload/ether-public/yitang/168283793060af79160a4760a3873115bba7b58f77968f9db9.png",
            "https://cdn.yitang.top/upload/ether-public/yitang/16821484275883256f80fe6ff793e0e446c05c7706cfe1c71d.png",
            "https://cdn.yitang.top/upload/ether-public/yitang/168274951516177c30b1028c98b52e7df82ce3bbf9f9a8e5b9.png",
          ],
        }
      },
      {
        path: '/promotion/management-dy',
        name: routeNameGroup.MANAGEMENTDY,
        component: () =>
            import( /* webpackChunkName: 'promotion-management' */ "./views/mobile/promotion/replay-rebuild.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•复盘营',
          shareInfo: {
            title: '以太一堂「复盘营」全新发布',
            desc: '务实创业+真实训练+切实有用，复盘营！'
          },
          bundle: 'management',
          special: false,
          fakeLogin: false, //假身份自动登录
          payButton: "https://cdn.yitang.top/upload/ether-public/yitang/168977429052b771bb6a02552c6ebd58df7696525a2b757be2.png",
          images: [
            'https://cdn.yitang.top/upload/ether-public/yitang/1689939950bdbb3e922832d13070946bf63df4c4916f7d0a43.png',
            'https://cdn.yitang.top/upload/ether-public/yitang/16897742909f405133b868701644494ef8e5f7b4fa42476306.png',
            'https://cdn.yitang.top/upload/ether-public/yitang/1689774290f07f1484e56309d457eae639bd6b6d090158d44d.png',
            'https://cdn.yitang.top/upload/ether-public/yitang/16897742906c117fd1c208e81aa52b816dae8c4882ab05c678.png',
            'https://cdn.yitang.top/upload/ether-public/yitang/16897742904f95ce5cc664a98e0607deb80cfd3013bf526046.png',
          ]
        }
      },
      {
        path: '/promotion/replay-dy-raw',
        component: () =>
            import( /* webpackChunkName: 'promotion-replayC' */ "./views/mobile/promotion/replay-dy-fake.vue"),
        meta: {
          requiresAuth: true,
          title: '以太一堂•复盘营',
          shareInfo: {
            title: '以太一堂「复盘营」全新发布',
            desc: '务实创业+真实训练+切实有用，复盘营！'
          },
          special: false,
          fakeLogin: true //假身份自动登录
        }
      },

      {
        path: '/promotion/treat/invite',
        component: () =>
          import( /* webpackChunkName: 'promotion-treatinvite' */ "./views/mobile/promotion/treatinvite.vue"),
        meta: {
          requiresAuth: true,
          asyncShare: true,
          shareInfo: {
            title: '我请你学「一堂训练营」，很有用！推荐给你！',
            desc: '每个业务负责人都应该听的第一堂课，我已付钱，请你来学，只有15份～'
          },
          title: '以太一堂',
        }
      },
      {
        path: '/promotion/mytreat',
        component: () =>
          import( /* webpackChunkName: 'promotion-treatinvite' */ "./views/mobile/promotion/mytreat.vue"),
        meta: {
          requiresAuth: true,
          title: '出圈专属页面',
          asyncShare: true,
          shareInfo: {
            title: '我请你学「一堂训练营」，很有用！推荐给你！',
            desc: '每个业务负责人都应该听的第一堂课，我已付钱，请你来学，只有15份～'
          },
          baseAuth: true //静默白名单
        }
      },
      {
        path: '/promotion/equity',
        name: routeNameGroup.EQUITY,
        component: () =>
          import( /* webpackChunkName: 'promotion-admission' */ "./views/mobile/promotion/replay-rebuild.vue"),
        meta: {
          requiresAuth: true,
          title: '线上实战营12.2开营',
          shareInfo: {
            title: '以太一堂“合伙股权7天营”火热招生，快来报名股权实战营',
            desc: '科学分钱，扫除股权盲区，不给未来埋雷'
          },
          special: true
        }
      },
      {
        path: '/promotion/a/equity',
        component: () =>
          import( /* webpackChunkName: 'promotion-admission' */ "./views/mobile/promotion/replay-double.vue"),
        meta: {
          requiresAuth: true,
          title: '线上实战营12.2开营',
          shareInfo: {
            title: '以太一堂“合伙股权7天营”火热招生，快来报名股权实战营',
            desc: '科学分钱，扫除股权盲区，不给未来埋雷'
          },
          special: true
        }
      },
      {
        path: '/promotion/b/equity',
        component: () =>
          import( /* webpackChunkName: 'promotion-admission' */ "./views/mobile/promotion/replay-double.vue"),
        meta: {
          requiresAuth: true,
          title: '线上实战营12.2开营',
          shareInfo: {
            title: '以太一堂“合伙股权7天营”火热招生，快来报名股权实战营',
            desc: '科学分钱，扫除股权盲区，不给未来埋雷'
          },
          special: true
        }
      },
      {
        path: '/promotion/equity-acc',
        redirect: '/promotion/sales/member',
        component: () =>
          import( /* webpackChunkName: 'promotion-admission' */ "./views/mobile/promotion/equity-acc.vue"),
        meta: {
          requiresAuth: true,
          title: '股权落地计划',
          shareInfo: {
            title: '最后几个名额，仅招募50名的股权落地计划',
            desc: '双师辅导，设计并落地一份优秀的股权方案'
          },
          special: true
        }
      },
      {
        path: '/promotion/admission-special',
        name: routeNameGroup.ADMISSIONSPECIAL,
        component: () =>
          import( /* webpackChunkName: 'promotion-admission' */ "./views/mobile/promotion/replay-rebuild.vue"),
        meta: {
          requiresAuth: true,
          title: '线上实战营12.2开营',
          shareInfo: {
            title: '以太一堂"7天改变计划"火热招生，快来报名融资突破',
            desc: '张一鸣天使投资人周子敬主理，手把手带你学习融资的全套打法'
          },
          special: false
        }
      },
      {
        path: '/promotion/camp/:type',
        redirect: '/promotion/replay',
        component: () =>
          import( /* webpackChunkName: 'promotion-camp' */ "./views/mobile/promotion/camp.vue"),
        meta: {
          requiresAuth: true,
          asyncShare: true
        }
      },
      {
        path: '/promotion/financing-acc',
        redirect: '/promotion/salses/member',
        component: () =>
          import( /* webpackChunkName: 'promotion-financing-acc' */ "./views/mobile/promotion/financing-acc.vue"),
        meta: {
          requiresAuth: true,
          title: '融资加速计划',
          shareInfo: {
            title: '最后几个名额，仅招募50名的融资加速计划',
            desc: '程浩、周子敬亲授，帮你跳过99%创业的坑'
          }
        }
      },
      {
        path: '/promotion/financing-acc-36kr',
        redirect: '/promotion/sales/member',
        component: () =>
          import( /* webpackChunkName: 'promotion-financing-acc-36kr' */ "./views/mobile/promotion/financing-acc-36kr.vue"),
        meta: {
          requiresAuth: true,
          title: '36氪&以太一堂 创始人俱乐部',
          shareInfo: {
            title: '36氪&以太一堂“创始人俱乐部”火热招募，快来加速融资',
            desc: '180天3轮融资加速，掌握以太融资全套打法；365天持续享受36氪&以太创投助力',
            imgUrl: 'https://cdn.yitang.top/upload/ether-public/platform/1575875114331f508cc25e1a8dea656d172fe896790e918df0.png'
          }
        }
      },
      {
        path: '/labs',
        component: () =>
          import( /* webpackChunkName: 'labs' */ "./views/mobile/labs.vue"),
        meta: {
          requiresAuth: true,
          title: '一堂 Labs',
        }
      },
      {
        path: '/promotion/financing-acc-reserve',
        component: () =>
          import( /* webpackChunkName: 'promotion-financing-acc-reserve' */ "./views/mobile/promotion/financing-acc-reserve.vue"),
        meta: {
          requiresAuth: true,
          title: '融资加速计划',
          asyncShare: true
        }
      },
      {
        path: '/promotion/camp-verify-reserve',
        component: () =>
          import( /* webpackChunkName: 'promotion-camp-verify-reserve' */ "./views/mobile/promotion/camp-verify-reserve.vue"),
        meta: {
          requiresAuth: true,
          title: '高潜力创业者项目培养计划',
          shareInfo: {
            title: '周子敬、程浩亲授的高潜力项目培养计划，仅招募30名',
            desc: '6周陪伴式指导,结束低效创业,加速项目成长,跑通业务模型',
            imgUrl: 'https://cdn.yitang.top/upload/ether-public/platform/1575875114331f508cc25e1a8dea656d172fe896790e918df0.png'
          },
          asyncShare: true
        }
      },
      {
        path: '/promotion/open-2021',
        component: () =>
          import( /* webpackChunkName: 'promotion-open' */ "./views/mobile/promotion/open-2021.vue"),
        meta: {
          requiresAuth: false,
          title: '一堂开年大课',
          shareInfo: {
            title: '一堂开年大课',
            desc: '电脑听课快速通道',
            imgUrl: 'https://cdn.yitang.top/upload/ether-public/platform/1575875114331f508cc25e1a8dea656d172fe896790e918df0.png'
          },
          asyncShare: true
        }
      },
      {
        path: '/forbidden',
        component: () =>
          import( /* webpackChunkName: 'forbidden' */ "./views/mobile/forbidden.vue"),
        meta: {
          showToHome: true,
          asyncShare: true
        }
      },
      {
        path: '/logout',
        component: () =>
          import( /* webpackChunkName: 'logout' */ "./views/mobile/logout.vue"),
        meta: {
          asyncShare: true
        }
      },
      {
        path: '/activity/signin',
        component: () =>
          import( /* webpackChunkName: 'promotion-activity' */ "./views/mobile/promotion/signin.vue"),
        meta: {
          requiresAuth: true,
          title: '一堂签到中心',
          shareInfo: {
            title: '一堂签到中心',
          }
        }
      },
      {
        path: '/index',
        component: () =>
          import( /* webpackChunkName: 'mobile-index' */ "./views/mobile/index.vue"),
        children: [{
            path: '',
            component: () =>
              import( /* webpackChunkName: 'mobile-lesson-list' */ "./views/mobile/lesson/list.vue"),
            meta: {
              requiresAuth: true,
              title: '一堂创业课',
            },
            beforeEnter: (to, from, next) => {
              appendElementIcon(next)
            }
          },


          {
            path: '/lessonlist/:id',
            component: () =>
              import( /* webpackChunkName: 'mobile-lesson-list-id' */ "./views/mobile/lesson/list-id.vue"),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: '/mba',
            component: () =>
              import( /* webpackChunkName: 'mba' */ "./views/mobile/mba/index.vue"),
            meta: {
              requiresAuth: true,
              title: '一堂MBA会员',
            }
          },
          {
            path: '/elective-lesson',
            component: () =>
              import( /* webpackChunkName: 'mobile-lesson-elective' */ "./views/mobile/curriculum/index.vue"),
            meta: {
              requiresAuth: true,
              title: '我的课程',
              keepAlive: true,
              showToHome:false,
            },
            beforeEnter: (to, from, next) => {
              //html2canvas
              appendPlugins('https://cdn.yitang.top/upload/ether-public/yitang/1703146526728a306423b87316c16f9d6222dc4b9dc90ee33f.js')
              appendElementIcon(next)
            },
            scrollBehavior(to, from, savedPosition) {
              if (savedPosition) {
                  return savedPosition; 
              } else {
                  return { x: 0, y: 0 }; 
              }
            }
          },
          {
            path: '/home',
            component: () =>
              import( /* webpackChunkName: 'mobile-lesson-elective' */ "./views/mobile/home/index.vue"),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: '/homework',
            component: () =>
              import( /* webpackChunkName: 'mobile-homework-list' */ "./views/mobile/homework/new-list.vue"),
            meta: {
              requiresAuth: true,
              title: '我的作业',
              keepAlive: true,
              showToHome:false,
            },
            beforeEnter: (to, from, next) => {
              appendPlugins('https://cdn.yitang.top/upload/ether-public/yitang/1703146526728a306423b87316c16f9d6222dc4b9dc90ee33f.js')
              appendElementIcon(next)
            },
            scrollBehavior(to, from, savedPosition) {
              if (savedPosition) {
                  return savedPosition; 
              } else {
                  return { x: 0, y: 0 }; 
              }
            }
          },
          {
            path: '/homework/:lessonId/view',
            component: () =>
              import( /* webpackChunkName: 'mobile-homework-list' */ "./views/mobile/homework/list.vue"),
            meta: {
              requiresAuth: true
            }
          }
        ],
      },
      {
        path: '/activity/:id/edit',
        component: () =>
          import( /* webpackChunkName: 'homework-edit' */ "./views/mobile/homework/edit.vue"),
        meta: {
          requiresAuth: true,
          page: 'activity'
        }
      },
      {
        path: '/card-submit',
        component: () =>
          import( /* webpackChunkName: 'mobile-lesson-elective' */ "./views/mobile/home/card/card-submit.vue"),
        meta: {
          requiresAuth: true,
          title:'填写我的“一堂名片”'
        }
      },
      {
        path: '/card',
        component: () =>
          import( /* webpackChunkName: 'mobile-lesson-elective' */ "./views/mobile/home/card/index.vue"),
        meta: {
          requiresAuth: true,
          title:'我的一堂名片'
        }
      },
      {
        path: '/card-user',
        component: () =>
          import( /* webpackChunkName: 'mobile-lesson-elective' */ "./views/mobile/home/card/card-user.vue"),
        meta: {
          requiresAuth: true,
          title:'我的一堂名片'
        }
      },
      {
        path: '/card-detail',
        component: () =>
          import( /* webpackChunkName: 'mobile-lesson-elective' */ "./views/mobile/home/card/card-detail.vue"),
        meta: {
          requiresAuth: true,
          title:'我的一堂名片详情'
        }
      },
      {
        path: '/candy-list-all',
        component: () =>
          import( /* webpackChunkName: 'mobile-lesson-elective' */ "./views/mobile/home/candy-list-all.vue"),
        meta: {
          requiresAuth: true,
          title:'一堂Candy合集'
        },
        beforeEnter: (to, from, next) => {
          appendElementIcon(next)
        }
      },
      {
        path: '/candy-list',
        component: () =>
          import( /* webpackChunkName: 'mobile-lesson-elective' */ "./views/mobile/home/candy-list.vue"),
        meta: {
          requiresAuth: true,
          title:'一堂Candy合集'
        }
      },
      {
        path: '/my-coupon',
        component: () =>
          import( /* webpackChunkName: 'mobile-lesson-elective' */ "./views/mobile/home/coupon/index.vue"),
        meta: {
          requiresAuth: true,
          title:'我的选课券'
        },
        beforeEnter: (to, from, next) => {
          appendElementIcon(next)
        }
      },
      {
        path: '/lesson/:id',
        component: () =>
          import( /* webpackChunkName: 'lesson-detail' */ "./views/mobile/lesson/detail.vue"),
        meta: {
          requiresAuth: true,
          asyncShare: true,
          showToHome: true
        }
      },
      {
        path: '/lesson/section/:sid',
        component: () =>
          import( /* webpackChunkName: 'lesson-section-detail' */ "./views/mobile/lesson/section.vue"),
        meta: {
          requiresAuth: true,
          asyncShare: true,
          showToHome: true
        }
      },
      {
        path: '/lesson/:id/confirm',
        component: () =>
          import( /* webpackChunkName: 'lesson-confirm' */ "./views/mobile/lesson/confirm.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/lesson/:id/select-coupon/',
        component: () =>
          import( /* webpackChunkName: 'select-coupon' */ "./views/mobile/lesson/select-coupon.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/fupan',
        component: () =>
          import( /* webpackChunkName: 'fupan' */ "./views/mobile/lesson/fupan.vue"),
        meta: {
          requiresAuth: true,
          title: '商业复盘实战营',
        }
      },
      {
        path: '/search',
        component: () =>
          import( /* webpackChunkName: 'fupan' */ "./views/mobile/lesson/search.vue"),
        meta: {
          requiresAuth: true,
          title: '一堂选课搜索中心',
        },
        beforeEnter: (to, from, next) => {
          appendElementIcon(next)
        }
      },
      {
        path: '/select',
        redirect: to => {
          const queryParams = to.query;
          return { path: '/index', query: queryParams };
        },
        component: () =>
          import( /* webpackChunkName: 'fupan' */ "./views/mobile/lesson/lesson-list.vue"),
        meta: {
          requiresAuth: true,
          title: '一堂选课',
        },
        beforeEnter: (to, from, next) => {
          appendElementIcon(next)
        }
      },
      {
        path: '/latest-courses',
        component: () =>
          import( /* webpackChunkName: 'fupan' */ "./views/mobile/lesson/latest-courses.vue"),
        meta: {
          requiresAuth: true,
          title: '最新课程',
        },
        beforeEnter: (to, from, next) => {
          appendElementIcon(next)
        }
      },
      {
        path: '/multiple-lesson/:id',
        component: () =>
          import( /* webpackChunkName: 'multiple-lesson' */ "./views/mobile/lesson/multiple-lesson.vue"),
        meta: {
          headerImage: 'https://cdn.yitang.top/upload/ether-public/yitang/164923875370ae5c7c926236d0cbbea2387888b0fa10207f1b.png',
          footerImage: 'https://cdn.yitang.top/upload/ether-public/yitang/1649162580bff8cbb7b264e049c190e58ff3ce1d530ca3de67.png',
          directLessonButton: 'https://cdn.yitang.top/upload/ether-public/yitang/164974984530b3ed970115727685a8f599b5fd499b3d65d482.png',
          requiresAuth: true,
          shareInfo: {
            title: '一堂·会员内测课',
            desc: '一堂大会员专属内测课选课，火热报名中'
          },
        }
      },
      {
        path: '/lesson-list/:id',
        component: () =>
          import( /* webpackChunkName: 'multiple-lesson' */ "./views/mobile/lesson/multiple-lesson.vue"),
        meta: {
          headerImage: 'https://cdn.yitang.top/upload/ether-public/yitang/1679476746a91fd3e184388e725b0c160aededc486d39c4cb1.png',
          footerImage: '',
          directLessonButton: '',
          requiresAuth: true,
          keepAlive: true,
          shareInfo: {
            title: '一堂·会员内测课',
            desc: '一堂大会员专属内测课选课，火热报名中'
          },
        }
      },
      {
        path: '/challenge-lesson/:id',
        component: () =>
          import( /* webpackChunkName: 'challenge-lesson' */ "./views/mobile/lesson/challenge-lesson.vue"),
        meta: {
          requiresAuth: true,
          shareInfo: {
            title: '一堂·会员内测课',
            desc: '一堂大会员专属内测课选课，火热报名中'
          },
        }
      },
      {
        path: '/homework/submit/:isOnline',
        component: () =>
          import( /* webpackChunkName: 'homework-submit' */ "./views/mobile/homework/submit.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/homework/submit-activity',
        component: () =>
          import( /* webpackChunkName: 'homework-submit' */ "./views/mobile/homework/submit-activity.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/homework/share',
        component: () =>
          import( /* webpackChunkName: 'homework-share' */ "./views/mobile/homework/share.vue"),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: '/homework/:id',
        component: () =>
          import( /* webpackChunkName: 'homework-detail' */ "./views/mobile/homework/detail.vue"),
        meta: {
          requiresAuth: true,
          showToHome: true
        }
      },
      {
        path: '/homework/:id/edit',
        component: () =>
          import( /* webpackChunkName: 'homework-edit' */ "./views/mobile/homework/edit.vue"),
        meta: {
          requiresAuth: true,
          page: 'homework'
        }
      },
      {
        path: '/homework-rank',
        component: () =>
          import( /* webpackChunkName: 'homework-rank' */ "./views/mobile/homework/rank.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/effect-rank',
        component: () =>
          import( /* webpackChunkName: 'effect-rank' */ "./views/mobile/homework/effect-rank.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/activity/:activityId/members',
        component: () =>
          import( /* webpackChunkName: 'effect-rank' */ "./views/mobile/homework/register-form.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/homework-summary',
        component: () =>
          import( /* webpackChunkName: 'homework-summary' */ "./views/mobile/homework/summary.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/profile/edit',
        component: () =>
          import( /* webpackChunkName: 'profile-edit' */ "./views/mobile/profile/edit.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/training/:trainingName',
        component: () =>
          import( /* webpackChunkName: 'profile-edit' */ "./views/mobile/promotion/training.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/redirect',
        component: () =>
          import( /* webpackChunkName: 'redirect' */ "./views/mobile/redirect.vue"),
        meta: {
          asyncShare: true
        }
      },
      {
        path: '/alumni',
        component: () =>
          import( /* webpackChunkName: 'mba' */ "./views/mobile/mba/alumni.vue"),
        meta: {
          requiresAuth: true,
          title: '一堂MBA会员',
        }
      },
      {
        path: '/course',
        component: () =>
          import( /* webpackChunkName: 'mba' */ "./views/mobile/mba/course.vue"),
        meta: {
          requiresAuth: true,
          title: '一堂MBA会员',
        }
      },
      {
        path: '/mba-training',
        component: () =>
          import( /* webpackChunkName: 'mba' */ "./views/mobile/mba/training.vue"),
        meta: {
          requiresAuth: true,
          title: '一堂MBA会员',
        }
      },
      {
        path: '/mba-entry',
        component: () =>
          import( /* webpackChunkName: 'mba' */ "./views/mobile/mba/entry.vue"),
        meta: {
          requiresAuth: true,
          title: '一堂MBA会员',
        }
      },
      {
        path: '/peer-group',
        component: () =>
          import( /* webpackChunkName: 'mba' */ "./views/mobile/mba/peer.vue"),
        meta: {
          requiresAuth: true,
          title: '一堂MBA会员',
        }
      },
    ],
  },
};

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes: [{
    path: '/',
    component: () =>
      import( /* webpackChunkName: 'index' */ "./views/index.vue")
  }].concat(displayQueue.map(layout => {
    return Object.assign({
      path: '/layout',
    }, routes[layout]);
  })),
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  // 无效路由处理
  if (!to.matched.length) {
    return next({
      path: '/forbidden',
      replace: true
    });
  }
  if (!to.meta.requiresAuth) return next();
  let token = vway.ls.getGlobal('TOKEN');
  if (!token) {
    getToken(to, from, next);
  } else {
    next();
  }
});

function getToken(to, from, next) {
  if (!Ua.isWx() && !canFakeLogin(to)) {
    vway.ls.setGlobal('TOKEN', '__guest__', 3600 * 24 * 7);
    next();
  } else {
    if (canPreLogin(to)) {
      preLogin(to);
      to.query.cancel = 1;
      //next();   静默登录页面加载快慢会导致失败
    } else {
      if(canDelayLogin(to)){
        vway.ls.setGlobal('TOKEN', '__guest__', 3600 * 24 * 7);
        next();
      }else{
        next({
          path: '/login',
          query: {
            after_login: location.pathname + location.search + location.hash,
            isBase: 0,
            isFake: canFakeLogin(to) ? 1 : 0
          },
          replace: true
        });
      }
    }

  }
}

// 全局钩子
router.afterEach((to, from) => {
  // 页面配置
  initPage(to);
  // 全局初始化分享信息，
  initShare(to);
  // pv统计
  initPv(to, from);
  // 百度统计
  initBd();
});
router.onError(err => {
  err.pos = 'router';
  vway.jsLogger.error(err);
  // Loading chunk {n} failed bug暂时先怎么处理
  const pattern = /Loading chunk (.+) failed/g;
  const isChunkLoadFailed = err.message.match(pattern);
  if (isChunkLoadFailed && !~location.href.indexOf('#reload')) {
    history.replaceState(null, '', '#reload');
    util.reload();
  }
});

function initShare(route) {
  // 如果分享内容是异步获取的，则在页面中写了分享逻辑
  if (route.meta.asyncShare) return;
  let shareInfo = Object.assign({
    link: location.origin + route.fullPath
  }, route.meta.shareInfo || {});
  if (Ua.isIOS()) {
    new WxSDK(shareInfo);
  } else {
    // 安卓微信浏览器存在延迟问题，需要延迟执行，否则签名错误
    setTimeout(() => {
      new WxSDK(shareInfo);
    }, 50);
  }
}

function initPv(to, from) {
  let pvParams = { type: 'pv-load' },
    uids_page = (vway.ls.get('uids_page') || 0);
  // 当页面是第一次加载的时候matched为空
  if (from.matched.length) {
    pvParams.uids_refer = uids_page;
  }
  vway.ls.set('uids_page', ++uids_page, false);
  $.stat.add('pv', pvParams, false, to);
}

function initBd() {
  setTimeout(() => {
    let _hmt = _hmt || [];
    (function () {
      //每次执行前，先移除上次插入的代码
      let dom = document.getElementById('baidu_tj');
      dom && dom.parentNode.removeChild(dom);
      let hm = document.createElement('script');
      hm.src = 'https://hm.baidu.com/hm.js?b86d6f2795c5a7f47ee0c942cd4d1488';
      hm.id = 'baidu_tj';
      let s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }, 0);
}

function initPage(route) {
  route.meta.title && (document.title = route.meta.title);
  let _uds = route.query._uds;
  _uds && vway.ls.setGlobal('_uds', _uds, 'session');
}

function appendElementIcon(next){
  try {
    let link = document.createElement( "link" );
    // link.href = '//unpkg.com/element-ui@2.15.9/lib/theme-chalk/index.css';
    link.href = 'https://cdn.yitang.top/upload/ether-public/yitang/17004601516fdf0edd01a92841de4de9c45318dd1df9cdd894.css'
    link.type = "text/css";
    link.rel = "stylesheet";
    document.getElementsByTagName( "head" )[0].appendChild( link );
    next()
  } catch (error) {
    next()
  }
}
function appendPlugins(url){
  try {
    let hm = document.createElement('script');
    hm.src = url;
    document.getElementsByTagName( "head" )[0].appendChild( hm );
  } catch (error) {}
}
export default router;
