<template>
  <div
    v-if="children"
    class="block docx-text-block"
    :class="[`block-${children.blockId}`, children.blockStyles && children.blockStyles.textBackground ? 'background':'']"
  >
    <div class="text-block">
      <div class="text-editor" :class="pageStyle">
        <v-spantext :elements="elements"  :children="children"></v-spantext>
      </div>
    </div>
    <div class="text-children" style="padding-left: 25px;">
      <slot :olIndex="olIndex + 1"></slot>
    </div>
    <v-highlight :blockId="children.blockId"></v-highlight>
    <v-blockbackground v-if="children.blockStyles && children.blockStyles.textBackground" :backgroundColor="children.blockStyles.textBackground"></v-blockbackground>
  </div>
</template>
<script>
import { pageStyles, getEleStyle } from "../utils/style";
import { isUrlRegExp, openUrl } from "../utils/utils";
export default {
  name: "text-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
    olIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    elements() {
      return this.children.blockAttr.text.elements || [];
    },
    pageStyle() {
      let dataStyle = this.children.blockAttr.text.style;
      let className = "";
      for (let key in dataStyle) {
        if (pageStyles[key]) {
          let value = dataStyle[key];
          className += pageStyles[key][value] + "";
        }
      }
      return className;
    },
  },
  data() {
    return {
      getEleStyle,
      isUrlRegExp,
      openUrl,
    };
  },
  methods: {
    getLabelText(elements) {
      let str = ``;
      elements.forEach((item) => {
        str += item.text_run ? item.text_run.content : "";
      });
      return str;
    }
  },
};
</script>
<style >
.callout-block-children > .callout-render-unit > .block:first-child {
  margin-top: 0 !important;
}
.callout-block-children > .callout-render-unit .block:last-child {
  margin-bottom: 0 !important;
}
.docx-text-block .text-block {
  position: relative;
  height: auto;
}
.text-block,
.text-block-wrapper {
  margin: 0 -2px;
  padding: 0 2px;
}

.link-text-color {
  text-underline-offset: 0.2em;
  color: var(--text-link-hover);
  cursor: pointer;
  -webkit-text-decoration-color: var(--text-link-hover) !important;
  text-decoration-color: var(--text-link-hover) !important;
}
.link-text-color span {
  color: var(--text-link-hover);
}
.link-text-color:hover {
  text-decoration: underline !important;
}
</style>